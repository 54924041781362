export function getWearablePrompt(wearable1, wearable2) {
    return `
Please compare the following two wearables:
  - Wearable 1: ${wearable1}
  - Wearable 2: ${wearable2}

Fetch all the information from reliable sources.

I need a detailed comparison in **pure HTML format**, covering every possible specification unique to wearables.

**Specifications to include:**
1. **Fitness Tracking**:
    - Sensors (e.g., heart rate monitor, GPS, accelerometer)
    - Fitness features (e.g., step counting, sleep tracking, workout modes)
    - Health monitoring (e.g., blood oxygen level, ECG)

2. **Display**:
    - Type (e.g., AMOLED, LCD)
    - Size (in inches or mm)
    - Resolution (in pixels)
    - Always-on display (Yes/No)

3. **Design and Build**:
    - Dimensions (in mm)
    - Weight (in grams)
    - Materials used (e.g., stainless steel, silicone, leather)
    - Strap options (e.g., interchangeable, adjustable)
    - Water resistance rating (e.g., IP68, 5ATM)

4. **Battery**:
    - Capacity (in mAh)
    - Battery life (in days or hours)
    - Charging method (e.g., wireless, magnetic, USB-C)

5. **Connectivity**:
    - Bluetooth version
    - Compatibility with smartphones (iOS, Android)
    - GPS support (Yes/No)

6. **Audio Features** (for headphones):
    - Driver size and type (e.g., 40mm dynamic drivers)
    - Noise cancellation (active or passive)
    - Microphone quality and features

7. **Controls**:
    - Touchscreen (Yes/No)
    - Physical buttons (e.g., number and type)
    - Voice control support (Yes/No)

8. **Software and Apps**:
    - OS or firmware version
    - Special features (e.g., voice assistants, app integrations)

9. **Price**:
    - Include the official price in USD, EUR, JPY, and GBP for the default models. Mention this clearly.
    - Make sure the prices are all in the same line

10. **Release Date**:
    - The official launch date of the wearables.

**HTML Table Format:**
- The comparison **must** be provided as a clean, simple HTML table using only these tags: <table>, <tr>, <th>, and <td>.
- There should be no bullet points or unnecessary text outside the table.
- Compare Wearable 1 and Wearable 2 side by side.
- Ensure the table is well-organized with clear column headers for each specification (e.g., "Wearable 1", "Wearable 2").
- Make sure each row has one specification (e.g., "Fitness Tracking", "Display", etc.) with both wearables’ details in separate columns.

Example HTML structure:

\`\`\`
<table>
  <tr>
    <th>Specification</th>
    <th>Wearable 1</th>
    <th>Wearable 2</th>
  </tr>
  <tr>
    <td>Fitness Tracking</td>
    <td>Heart rate monitor, GPS, sleep tracking, 24/7 activity tracking</td>
    <td>Accelerometer, step counting, ECG, sleep tracking</td>
  </tr>
  <tr>
    <td>Display</td>
    <td>AMOLED, 1.4 inches, 454x454 pixels, Always-on</td>
    <td>LCD, 1.3 inches, 360x360 pixels, No</td>
  </tr>
  <!-- Continue for all specifications -->
</table>
\`\`\`

**Points-Based Verdict:**
- Calculate a **total score** for each wearable out of 100, based on all specification categories.
- Format the result as:

  - Wearable 1: Name [Total Points out of 100] points
  - Wearable 2: Name [Total Points out of 100] points

  <h4>Wearable Scores</h4>
  <h5>Wearable 1 and Total points out of 100</h5>
  <h5>Wearable 2 and Total points out of 100</h5>

  <h1>Winner</h1>
  <h5>The Wearable with the higher points Declared as the winner</h5>
  <h6>Short summary of this wearable is the winner</h6>

Provide the response only in plain HTML format with no additional text or commentary.
  ** COMPARE ONLY WEARABLES**
`;
}

