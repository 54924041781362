const getPrompt = (phone1, phone2) => `
 Please compare the following two phones:
  - Phone 1: ${phone1}
  - Phone 2: ${phone2}

  Fetch all the information from reliable sources.

  I need a detailed comparison in **pure HTML format**, covering every possible specification for each phone.

  **Specifications to include:**
  1. **Display**:
      - Type (e.g., OLED, IPS LCD)
      - Size (in inches)
      - Resolution (in pixels)
      - Protection (e.g., Gorilla Glass)

  2. **Processor**:
      - Type and model (e.g., Qualcomm Snapdragon 888, Apple A14)
      - Number of cores and clock speed (e.g., Octa-core, 2.84GHz)

  3. **Memory and Storage**:
      - RAM size (in GB)
      - Storage options (in GB or TB)
      - Expandable storage (Yes/No, and type)

  4. **Camera**:
      - Rear camera setup (number of cameras, megapixels, special features like OIS or night mode)
      - Front camera setup (number of cameras, megapixels)
      - Video recording capabilities (e.g., 4K at 60fps)

  5. **Battery**:
      - Capacity (in mAh)
      - Charging speed (wired and wireless)
      - Special features (e.g., fast charging, reverse wireless charging)

  6. **Connectivity**:
      - Network support (5G, LTE, etc.)
      - Wi-Fi and Bluetooth versions
      - Other connectivity features (e.g., NFC, USB type)

  7. **Build and Design**:
      - Dimensions (in mm)
      - Weight (in grams)
      - Materials used (e.g., glass, aluminum)

  8. **Software**:
      - OS version 
      - Special software features or skin

  9. **Sensors**:
      - List of sensors (e.g., fingerprint sensor, accelerometer)

  10. **Price**:
      - Include the official price in USD, EUR, JPY, and GBP for the default models. Mention this clearly.
      - Make sure the prices are all in the same line

  11. **Release Date**:
      - The official launch date of the phones.

  **HTML Table Format:**
  - The comparison **must** be provided as a clean, simple HTML table using only these tags: <table>, <tr>, <th>, and <td>.
  - There should be no bullet points or unnecessary text outside the table.
  - Compare Phone 1 and Phone 2 side by side.
  - Ensure the table is well-organized with clear column headers for each specification (e.g., "Phone 1", "Phone 2").
  - Make sure each row has one specification (e.g., "Display", "Processor", etc.) with both phones’ details in separate columns.

  Example HTML structure:

  \`\`\`
  <table>
    <tr>
      <th>Specification</th>
      <th>Phone 1</th>
      <th>Phone 2</th>
    </tr>
    <tr>
      <td>Display</td>
      <td>OLED, 6.5 inches, 1080x2400 pixels</td>
      <td>IPS LCD, 6.1 inches, 1170x2532 pixels</td>
    </tr>
    <tr>
      <td>Processor</td>
      <td>Qualcomm Snapdragon 888, Octa-core, 2.84GHz</td>
      <td>Apple A14 Bionic, Hexa-core, 3.1GHz</td>
    </tr>
    <!-- Continue for all specifications -->
  </table>
  \`\`\`

  **Points-Based Verdict:**
  - Calculate a **total score** for each phone out of 100, based on all specification categories.
  - Format the result as:

    - Phone 1: Name [Total Points out of 100] points
    - Phone 2: Name [Total Points out of 100] points
   <h4>Phone Scores</h4>
   <h5>Phone1 and Total points out of 100 </h5>
   <h5>Phone2 and Total points out of 100 </h5>

   <h4>Winner</h4>
   <h5>The Phone with the higher points Declared as the winner</h5>
   <h6>Short summary of this phone is the winner </h6>

  Provide the response only in plain HTML format with no additional text or commentary.
  ** COMPARE ONLY PHONES**
  `;


export default getPrompt;
 