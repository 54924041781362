import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeScreen from './Homescreen';
import AppAppBar from './Components/Nav/Appbar';
import Footer from './Components/Footer/Footer.js'
import './All.css'

function App() {
  return (
    <Router>
      <div className="App">
        <AppAppBar />
        <div className="content-container">
          <Routes>
            <Route path="/" element={<HomeScreen />} />

           
          </Routes>
        </div>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
