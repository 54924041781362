export function getLaptopPrompt(laptop1, laptop2) {
    return `
    Please compare the following two laptops:
    - Laptop 1: ${laptop1}
    - Laptop 2: ${laptop2}
  
    Fetch all the information from reliable sources.
  
    I need a detailed comparison in **pure HTML format**, covering every possible specification for each laptop.
  
    **Specifications to include:**
    1. **Display**:
        - Type (e.g., IPS LCD, OLED)
        - Size (in inches)
        - Resolution (in pixels)
        - Refresh Rate (e.g., 60Hz, 120Hz)
  
    2. **Processor**:
        - Type and model (e.g., Intel Core i7-12700K, AMD Ryzen 9 7900X)
        - Number of cores and clock speed (e.g., Hexa-core, 3.6GHz)
  
    3. **Memory (RAM)**:
        - Size (in GB)
        - Type (e.g., DDR4, LPDDR5)
  
    4. **Storage**:
        - Type and capacity (e.g., SSD 512GB, HDD 1TB)
        - Expandable storage (Yes/No, and type)
  
    5. **Graphics**:
        - GPU type and model (e.g., NVIDIA GeForce RTX 3080, Intel Iris Xe)
        - Dedicated or integrated
  
    6. **Operating System**:
        - OS version (e.g., Windows 11, macOS Monterey)
  
    7. **Display Ports**:
        - Available ports (e.g., HDMI, USB-C, Mini DisplayPort)
  
    8. **Design and Build**:
        - Dimensions (in mm)
        - Weight (in grams)
        - Materials used (e.g., aluminum, plastic)
  
    9. **Keyboard**:
        - Type (e.g., backlit, mechanical)
        - Key features (e.g., number pad, key travel)
  
    10. **Battery**:
        - Capacity (in Wh)
        - Battery life (in hours)
        - Charging speed (e.g., fast charging)
  
    11. **Connectivity**:
        - Network support (e.g., Wi-Fi 6, Ethernet)
        - Bluetooth version
        - Other connectivity features (e.g., USB 3.2, Thunderbolt)
  
    12. **Other Features**:
        - Special features (e.g., fingerprint sensor, facial recognition)
  
    13. **Price**:
        - Include the official price in USD, EUR, JPY, and GBP for the default models. Mention this clearly.
        - Make sure the prices are all in the same line
  
    14. **Release Date**:
        - The official launch date of the laptops.
  
    **HTML Table Format:**
    - The comparison **must** be provided as a clean, simple HTML table using only these tags: <table>, <tr>, <th>, and <td>.
    - There should be no bullet points or unnecessary text outside the table.
    - Compare Laptop 1 and Laptop 2 side by side.
    - Ensure the table is well-organized with clear column headers for each specification (e.g., "Laptop 1", "Laptop 2").
    - Make sure each row has one specification (e.g., "Display", "Processor", etc.) with both laptops’ details in separate columns.
  
    Example HTML structure:
  
    \`\`\`
    <table>
      <tr>
        <th>Specification</th>
        <th>Laptop 1</th>
        <th>Laptop 2</th>
      </tr>
      <tr>
        <td>Display</td>
        <td>IPS LCD, 15.6 inches, 1920x1080 pixels, 60Hz</td>
        <td>OLED, 14 inches, 2560x1600 pixels, 120Hz</td>
      </tr>
      <tr>
        <td>Processor</td>
        <td>Intel Core i7-12700K, Hexa-core, 3.6GHz</td>
        <td>AMD Ryzen 9 7900X, Octa-core, 4.0GHz</td>
      </tr>
      <tr>
        <td>Memory (RAM)</td>
        <td>16GB DDR4</td>
        <td>32GB LPDDR5</td>
      </tr>
      <tr>
        <td>Storage</td>
        <td>SSD 512GB</td>
        <td>HDD 1TB</td>
      </tr>
      <tr>
        <td>Graphics</td>
        <td>NVIDIA GeForce RTX 3080</td>
        <td>Intel Iris Xe</td>
      </tr>
      <tr>
        <td>Operating System</td>
        <td>Windows 11</td>
        <td>macOS Monterey</td>
      </tr>
      <tr>
        <td>Display Ports</td>
        <td>HDMI, USB-C</td>
        <td>Mini DisplayPort, USB-C</td>
      </tr>
      <tr>
        <td>Design and Build</td>
        <td>Dimensions: 355x230x18 mm, Weight: 1.8 kg, Material: Aluminum</td>
        <td>Dimensions: 310x220x16 mm, Weight: 1.4 kg, Material: Plastic</td>
      </tr>
      <tr>
        <td>Keyboard</td>
        <td>Backlit, Number pad</td>
        <td>Backlit, Mechanical keys</td>
      </tr>
      <tr>
        <td>Battery</td>
        <td>Capacity: 70 Wh, Battery life: 8 hours, Charging: Fast charging</td>
        <td>Capacity: 50 Wh, Battery life: 10 hours, Charging: Standard charging</td>
      </tr>
      <tr>
        <td>Connectivity</td>
        <td>Wi-Fi 6, Bluetooth 5.2, USB 3.2, Thunderbolt 4</td>
        <td>Wi-Fi 6E, Bluetooth 5.1, USB 3.1, Thunderbolt 3</td>
      </tr>
      <tr>
        <td>Other Features</td>
        <td>Fingerprint sensor, Facial recognition</td>
        <td>Facial recognition, IR camera</td>
      </tr>
      <tr>
        <td>Price</td>
        <td>$1,499, €1,399, ¥200,000, £1,199</td>
        <td>$1,299, €1,199, ¥180,000, £1,099</td>
      </tr>
      <tr>
        <td>Release Date</td>
        <td>January 2023</td>
        <td>March 2023</td>
      </tr>
    </table>
    \`\`\`
  
 **Points-Based Verdict:**
- Calculate a **total score** for each laptop out of 100, based on all specification categories.
- Format the result as:

  - Laptop 1: Name [Total Points out of 100] points
  - Laptop 2: Name [Total Points out of 100] points

  <h4>Laptop Scores</h4>
  <h5>Laptop1 and Total points out of 100</h5>
  <h5>Laptop2 and Total points out of 100</h5>

  <h4>Winner</h4>
  <h5>The Laptop with the higher points Declared as the winner</h5>
  <h6>Short summary of this laptop is the winner</h6>
  
    Provide the response only in plain HTML format with no additional text or commentary.
      ** COMPARE ONLY LAPTOPS**
    `;
  }
  