export function getHeadPhonesPrompt(headphone1, headphone2) {
    return `    Please compare the following two headphones:
      - Headphone 1: ${headphone1}
      - Headphone 2: ${headphone2}
  
    Fetch all the information from reliable sources.

    I need a detailed comparison in **pure HTML format**, covering every possible specification for each headphone.

    **Specifications to include:**
    1. **Design and Build**:
        - Form factor (e.g., over-ear, on-ear, in-ear)
        - Weight (in grams)
        - Materials used (e.g., plastic, aluminum, leather)
        - Foldability or collapsible features

    2. **Sound Quality**:
        - Driver type (e.g., dynamic, planar magnetic)
        - Driver size (in mm)
        - Frequency response (Hz)
        - Impedance (Ohms)
        - Sensitivity (dB)
        - THD (Total Harmonic Distortion)
        - Sound profile (e.g., bass-heavy, balanced, V-shaped)

    3. **Noise Cancellation**:
        - Type of noise cancellation (e.g., active, passive)
        - Effectiveness (mention decibel reduction if available)
        - Ambient mode or transparency feature

    4. **Connectivity**:
        - Bluetooth version (e.g., 5.0, 5.1)
        - Wireless range (in meters)
        - Supported codecs (e.g., aptX, AAC, LDAC)
        - Multi-device pairing (Yes/No)

    5. **Battery Life**:
        - Battery life (in hours) with ANC on and off
        - Charging speed (e.g., fast charging)
        - Charging port (e.g., USB-C, micro USB)
        - Wireless charging (Yes/No)

    6. **Comfort and Fit**:
        - Ear pad material (e.g., memory foam, leather)
        - Headband adjustability
        - Pressure points or clamping force information
        - Earcup swivel and rotation

    7. **Controls and Features**:
        - Touch or physical controls
        - Voice assistant support (e.g., Siri, Google Assistant)
        - Smart features (e.g., auto-pause when taken off)
        - Customizable EQ (Yes/No)

    8. **Microphone**:
        - Microphone type (e.g., boom mic, inline mic)
        - Number of microphones
        - Mic clarity for calls (mention if rated for noise isolation)

    9. **Durability**:
        - Water resistance (e.g., IPX rating)
        - Build quality (mention if there are any durability certifications)

    10. **Price**:
        - Include the official price in USD, EUR, JPY, and GBP for the default models. Mention this clearly.
        - Make sure the prices are all in the same line.

    11. **Release Date**:
        - The official launch date of the headphones.

    **HTML Table Format:**
    - The comparison **must** be provided as a clean, simple HTML table using only these tags: <table>, <tr>, <th>, and <td>.
    - There should be no bullet points or unnecessary text outside the table.
    - Compare Headphone 1 and Headphone 2 side by side.
    - Ensure the table is well-organized with clear column headers for each specification (e.g., "Headphone 1", "Headphone 2").
    - Make sure each row has one specification (e.g., "Sound Quality", "Noise Cancellation", etc.) with both headphones’ details in separate columns.

    Example HTML structure:

    \`\`\`
    <table>
      <tr>
        <th>Specification</th>
        <th>Headphone 1</th>
        <th>Headphone 2</th>
      </tr>
      <tr>
        <td>Design</td>
        <td>Over-ear, 350 grams, aluminum build</td>
        <td>On-ear, 250 grams, plastic build</td>
      </tr>
      <tr>
        <td>Sound Quality</td>
        <td>Dynamic drivers, 40mm, 20Hz-20kHz</td>
        <td>Planar magnetic drivers, 50mm, 10Hz-40kHz</td>
      </tr>
      <!-- Continue for all specifications -->
    </table>
    \`\`\`

    **Points-Based Verdict:**
    - Calculate a **total score** for each headphone out of 100, based on all specification categories.
    - Format the result as:

      - Headphone 1: Name [Total Points out of 100] points
      - Headphone 2: Name [Total Points out of 100] points
     <h4>Headphone Scores</h4>
     <h5>Headphone1 and Total points out of 100 </h5>
     <h5>Headphone2 and Total points out of 100 </h5>

     <h4>Winner</h4>
     <h5>The headphone with the higher points is declared as the winner</h5>
     <h6>Short summary of why this headphone is the winner</h6>

    Provide the response only in plain HTML format with no additional text or commentary.
      ** COMPARE ONLY hEADPHONES**
   `;
  }
  